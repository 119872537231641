// Importiere die benötigten Funktionen aus dem Firebase SDK
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Importiere die Authentifizierungsfunktion
import { getFirestore, collection, addDoc } from "firebase/firestore"; // Importiere Firestore, die collection-Funktion und addDoc
import { getStorage } from "firebase/storage"; // Importiere Storage
import { serverTimestamp } from "firebase/firestore"; // Importiere serverTimestamp

// Deine Firebase-Konfiguration
const firebaseConfig = {
    apiKey: "AIzaSyAn8JBfLX8DW2Gzp7CoIMvNSH8Se4Qvgjg",
    authDomain: "jewlai2.firebaseapp.com",
    projectId: "jewlai2",
    storageBucket: "jewlai2.appspot.com",
    messagingSenderId: "119717394273",
    appId: "1:119717394273:web:0747584d81391fd01e3720"
};

// Initialisiere Firebase
const app = initializeApp(firebaseConfig);

// Initialisiere die Authentifizierungsinstanz
const auth = getAuth(app);

// Initialisiere Firestore
const firestore = getFirestore(app);

// Initialisiere Storage
const storage = getStorage(app);

// Exportiere die Instanzen für die Verwendung in anderen Teilen der Anwendung
export { auth, firestore, storage, collection, addDoc, serverTimestamp }; // Füge serverTimestamp zum Export hinzu
