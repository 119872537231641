// src/upload2R2.js
import * as AWS from "aws-sdk";

export async function uploadToR2(file) {
    const s3 = new AWS.S3({
        region: "auto",
        signatureVersion: "v4",
        credentials: new AWS.Credentials("0ce0655eac9a3dcf0dc76383d585be93", "8abf46e48074bb150ca752c382aa8dfb30041491068097a939d0ae9c5641be63"),
        endpoint: "https://7ff81df9924eceed02f95392507dbe90.r2.cloudflarestorage.com",
    });

    // Generate a unique filename by appending a timestamp
    const uniqueFileName = `${Date.now()}-${file.name}`;

    const params = {
        Bucket: "jewlai",
        Key: `uploads/${uniqueFileName}`, // Use the unique filename here
        Body: file,
        ContentType: file.type,
        ACL: "public-read",
    };
    await s3.upload(params).promise();
    return `https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/uploads/${uniqueFileName}`; // Return the URL with the unique filename
}

