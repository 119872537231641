// src/CoordinateSystem.js
import React, { useEffect, useState, useRef } from 'react';
import { uploadToR2 } from './uploadToR2'; // Importiere die uploadToR2 Funktion
import { firestore, addDoc } from "./firebase"; // Import Firestore functions
import { serverTimestamp, onSnapshot, query, orderBy, collection, doc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore functions

const CoordinateSystem = ({ newModelImage, imageUrlRb }) => {
    const [images, setImages] = useState([]);
    const [dragging, setDragging] = useState(null);
    const [resizing, setResizing] = useState(null);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [resizeStart, setResizeStart] = useState({ x: 0, y: 0 });
    const [combinedImageUrl, setCombinedImageUrl] = useState(null); // Zustand für das kombinierte Bild
    const [combinedCutOut, setCombinedCutOut] = useState(null); // Zustand für das kombinierte Bild
    const coordinateSystemRef = useRef(null); // Referenz für das Koordinatensystem
    const [originalDimensions, setOriginalDimensions] = useState({}); // Zustand für Originaldimensionen
    const [error, setError] = useState(null); // Zustand für Fehler
    const [userId, setUserId] = useState(null); // Zustand für die Benutzer-ID
    const [docRefId, setDocRefId] = useState(null); // Zustand für die Dokumentreferenz-ID

    // useEffect, um die URL auszulesen, die userId und docRefId zu setzen und das imageUrlRb in das images-Array zu laden
    useEffect(() => {
        const url = window.location.href; // Aktuelle URL abrufen
        console.log('Current URL:', url); // Debugging-Log
        const urlParts = url.split('/'); // URL in Teile aufteilen
        console.log('URL Parts:', urlParts); // Debugging-Log

        // Überprüfen, ob die URL die erwartete Struktur hat
        if (urlParts.length >= 7) { // Hier auf 7 Teile prüfen
            const userIdFromUrl = urlParts[5]; // userId ist der fünfte Teil
            const docRefIdFromUrl = urlParts[7]; // docRefId ist der siebte Teil

            setUserId(userIdFromUrl); // Setze die Benutzer-ID
            setDocRefId(docRefIdFromUrl); // Setze die Dokumentreferenz-ID

            console.log('User ID:', userIdFromUrl);
            console.log('Document Reference ID:', docRefIdFromUrl);

            if (imageUrlRb) {
                const img = new Image();
                img.src = imageUrlRb; // Bildquelle setzen

                img.onload = () => {
                    console.log(`Loaded imageUrlRb: ${imageUrlRb} with dimensions: ${img.width}x${img.height}`);
                    const aspectRatio = img.width / img.height; // Berechne das Seitenverhältnis
                    const scaledHeight = 400; // Höhe des Koordinatensystems
                    const scaledWidth = scaledHeight * aspectRatio; // Berechne die Breite basierend auf der Höhe

                    // Speichere die Originalauflösung mit der URL als Schlüssel
                    setOriginalDimensions(prev => ({
                        ...prev,
                        [imageUrlRb]: { width: img.width, height: img.height }, // Speichere die Originalgröße
                    }));

                    // Setze die Originalbreite und -höhe, wenn das Bild geladen ist
                    setImages(prevImages => {
                        const imageIndex = prevImages.findIndex(image => image.url === imageUrlRb);
                        if (imageIndex === -1) {
                            return [
                                ...prevImages,
                                { url: imageUrlRb, x: 150, y: 150, width: scaledWidth, height: scaledHeight }, // Skaliert
                            ];
                        }
                        return prevImages; // Wenn es bereits vorhanden ist, nichts ändern
                    });
                };

                img.onerror = () => {
                    console.error(`Failed to load imageUrlRb: ${imageUrlRb}`);
                };
            }
        } else {
            console.error('URL does not have the expected structure.');
        }
    }, [imageUrlRb]); // Abhängigkeit: Wenn imageUrlRb sich ändert

    // useEffect, um das newModelImage in das images-Array zu laden oder zu ersetzen
    useEffect(() => {
        if (newModelImage) {
            const img = new Image();
            img.src = newModelImage; // Bildquelle setzen

            img.onload = () => {
                console.log(`Loaded newModelImage: ${newModelImage} with dimensions: ${img.width}x${img.height}`);
                const aspectRatio = img.width / img.height; // Berechne das Seitenverhältnis
                const scaledHeight = 400; // Höhe des Koordinatensystems
                const scaledWidth = scaledHeight * aspectRatio; // Berechne die Breite basierend auf der Höhe

                // Speichere die Originalauflösung mit der URL als Schlüssel
                setOriginalDimensions(prev => ({
                    ...prev,
                    [newModelImage]: { width: img.width, height: img.height }, // Speichere die Originalgröße
                }));

                // Überprüfen, ob das Bild bereits im Array vorhanden ist
                const imageIndex = images.findIndex(image => image.url === newModelImage);
                if (imageIndex !== -1) {
                    // Wenn das Bild bereits vorhanden ist, aktualisiere nur die Position
                    setImages(prevImages => {
                        const updatedImages = [...prevImages];
                        updatedImages[imageIndex] = {
                            ...updatedImages[imageIndex],
                            x: 50, // Neue Position, wenn nötig
                            y: 50, // Neue Position, wenn nötig
                        };
                        return updatedImages;
                    });
                } else {
                    // Wenn das Bild nicht vorhanden ist, füge es mit den skalierten Abmessungen hinzu
                    setImages((prevImages) => {
                        const updatedImages = prevImages.filter(image => image.url === imageUrlRb);
                        return [
                            { url: newModelImage, x: 50, y: 50, width: scaledWidth, height: scaledHeight }, // Skaliert
                            ...updatedImages, // Behalte nur das Bild mit imageUrlRb
                        ];
                    });
                }
            };

            img.onerror = () => {
                console.error(`Failed to load newModelImage: ${newModelImage}`);
            };
        }
    }, [newModelImage]);

    const handleMouseDown = (index, e) => {
        setDragging(index);
        setOffset({ x: e.clientX - images[index].x, y: e.clientY - images[index].y });
    };

    const handleMouseUp = () => {
        setDragging(null);
        setResizing(null);
    };

    const handleMouseMove = (e) => {
        if (dragging !== null) {
            const newImages = [...images];
            if (dragging >= 0 && dragging < newImages.length) {
                newImages[dragging].x = e.clientX - offset.x; // Aktualisiere die x-Position
                newImages[dragging].y = e.clientY - offset.y; // Aktualisiere die y-Position
                setImages(newImages);
            }
        } else if (resizing !== null) {
            const newImages = [...images];
            if (resizing >= 0 && resizing < newImages.length) {
                const deltaX = e.clientX - resizeStart.x; // Berechne die Änderung in der X-Richtung
                const deltaY = e.clientY - resizeStart.y; // Berechne die Änderung in der Y-Richtung

                const aspectRatio = newImages[resizing].width / newImages[resizing].height;
                newImages[resizing].width = Math.max(newImages[resizing].width + deltaX, 20);
                newImages[resizing].height = Math.max(newImages[resizing].width / aspectRatio, 20);

                setImages(newImages);
                setResizeStart({ x: e.clientX, y: e.clientY }); // Aktualisiere die Startposition
            }
        }
    };

    const handleResizeMouseDown = (index, e) => {
        e.stopPropagation(); // Verhindert, dass das MouseDown-Ereignis das Bild zieht
        setResizing(index); // Setze den Index des Bildes, das resized wird
        setResizeStart({ x: e.clientX, y: e.clientY }); // Setze die Startposition für das Resize
    };

    // Funktion zum Aktualisieren des Firestore-Dokuments
    const updateImageInFirestore = async (serIdFromUrl, docRefIdFromUrl, processedImageUrl, choosedModelImage, cutOutLink, combinedCutOutWidth, combinedCutOutHeight) => {
        try {
            if (!serIdFromUrl || !docRefIdFromUrl) {
                console.error('userId or docRefId is undefined:', { serIdFromUrl, docRefIdFromUrl });
                return; // Beende die Funktion, wenn userId oder docRefId undefined ist
            }

            const imageRef = doc(firestore, 'users', serIdFromUrl, 'images', docRefIdFromUrl); // Verwende latestUserId hier
            const docSnapshot = await getDoc(imageRef); // Überprüfe, ob das Dokument existiert

            if (docSnapshot.exists()) {
                await updateDoc(imageRef, {
                    combinedImageUrl: processedImageUrl, // Füge die URL als 'combinedImageUrl' hinzu
                    combinedCutOutUrl: cutOutLink, // Füge die URL des ausgewählten Bildes hinzu
                    combinedCutOutWidth: combinedCutOutWidth, // Speichere die Breite als combinedCutOutWidth
                    combinedCutOutHeight: combinedCutOutHeight // Speichere die Höhe als combinedCutOutHeight
                });
                console.log(`Updated Firestore document for user ${serIdFromUrl} and image ${docRefIdFromUrl} with URLs: ${processedImageUrl}, ${choosedModelImage}, Width: ${combinedCutOutWidth}, Height: ${combinedCutOutHeight}`);
            } else {
                console.log(`Document does not exist for user ${serIdFromUrl} and image ${docRefIdFromUrl}.`);
            }
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    // Funktion zum Kombinieren und Hochladen der Bilder
    const combineAndUploadImages = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Kombiniere das newModelImage
        const newImage = images.find(image => image.url === newModelImage);
        if (newImage) {
            // Berechne die Skalierungsfaktoren
            const originalWidth = originalDimensions[newModelImage]?.width || 1; // Originalbreite von newModelImage
            const originalHeight = originalDimensions[newModelImage]?.height || 1; // Originalhöhe von newModelImage
            const scaleX = 2 * newImage.width / originalWidth; // Berechne den Skalierungsfaktor X
            const scaleY = 2 * newImage.height / originalHeight; // Berechne den Skalierungsfaktor Y

            // Berechne die neue Größe basierend auf den Skalierungsfaktoren
            const newWidth = newImage.width / scaleX; // Teile die Breite durch den Skalierungsfaktor
            const newHeight = newImage.height / scaleY; // Teile die Höhe durch den Skalierungsfaktor

            canvas.width = newWidth * window.devicePixelRatio;
            canvas.height = newHeight * window.devicePixelRatio;

            // Zeichne jedes Bild auf das Canvas mit den entsprechenden Positionen und Größen
            for (const image of images) {
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // Setze das crossOrigin-Attribut
                img.src = image.url;

                await new Promise((resolve, reject) => {
                    img.onload = () => {
                        ctx.drawImage(img, (image.x - newImage.x) / scaleX * window.devicePixelRatio, (image.y - newImage.y) / scaleY * window.devicePixelRatio, image.width / scaleX * window.devicePixelRatio, image.height / scaleY * window.devicePixelRatio); // Zeichne das Bild relativ zum newModelImage
                        resolve();
                    };
                    img.onerror = reject;
                });
            }

            const combinedImageUrl = canvas.toDataURL('image/png');
            setCombinedImageUrl(combinedImageUrl);

            // Hochladen des kombinierten Bildes an Cloudflare R2
            const blob = await (await fetch(combinedImageUrl)).blob();
            try {
                const link = await uploadToR2(blob); // Hochladen an Cloudflare R2
                console.log('Uploaded Combined Image URL:', link);

                // Hier rufst du die Funktion auf, um die URL in Firestore zu speichern
                await updateImageInFirestore(userId, docRefId, link, null, null, null, null); // Setze combinedCutOutUrl auf null, da es hier nicht verwendet wird
            } catch (error) {
                console.error('Error uploading combined image to Cloudflare:', error);
                setError('Failed to upload combined image.'); // Benutzerbenachrichtigung
            }

            // Rufe die Funktion zum Kombinieren der CutOut-Bilder auf
            const cutOutUrl = await combineCutOut(); // Stelle sicher, dass diese Funktion aufgerufen wird
            if (cutOutUrl) {
                // Optional: Speichere auch die Cutout-URL in Firestore
                await updateImageInFirestore(userId, docRefId, cutOutUrl, newModelImage, null, null, null);
            }
        }
    };

    // Funktion zum Kombinieren der CutOut Bilder
    const combineCutOut = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Setze die Canvas-Größe auf die Größe des Bildes mit imageUrlRb
        const cutOutImage = images.find(image => image.url === imageUrlRb);
        if (cutOutImage) {
            // Berechne die Skalierungsfaktoren
            const originalWidth = originalDimensions[imageUrlRb]?.width || 1; // Originalbreite von imageUrlRb
            const originalHeight = originalDimensions[imageUrlRb]?.height || 1; // Originalhöhe von imageUrlRb

            // Logge die Originalbreite und -höhe in die Konsole
            console.log('Original Width:', originalWidth);
            console.log('Original Height:', originalHeight);

            const scaleX = 2 * cutOutImage.width / originalWidth; // Berechne den Skalierungsfaktor X
            const scaleY = 2 * cutOutImage.height / originalHeight; // Berechne den Skalierungsfaktor Y

            // Berechne die neue Größe basierend auf den Skalierungsfaktoren
            const newWidth = cutOutImage.width / scaleX;
            const newHeight = cutOutImage.height / scaleY;

            canvas.width = newWidth * window.devicePixelRatio;
            canvas.height = newHeight * window.devicePixelRatio;

            // Zeichne jedes Bild auf das Canvas mit den entsprechenden Positionen und Größen
            for (const image of images) {
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // Setze das crossOrigin-Attribut
                img.src = image.url;

                await new Promise((resolve, reject) => {
                    img.onload = () => {
                        ctx.drawImage(img, (image.x - cutOutImage.x) / scaleX * window.devicePixelRatio, (image.y - cutOutImage.y) / scaleY * window.devicePixelRatio, image.width / scaleX * window.devicePixelRatio, image.height / scaleY * window.devicePixelRatio); // Zeichne das Bild relativ zum cutOutImage
                        resolve();
                    };
                    img.onerror = reject;
                });
            }

            const combinedCutOutUrl = canvas.toDataURL('image/png');
            console.log('Combined CutOut URL:', combinedCutOutUrl); // Debugging-Log
            setCombinedCutOut(combinedCutOutUrl);

            // Breite und Höhe des kombinierten CutOut-Bildes auslesen
            const combinedCutOutWidth = originalWidth; // Breite in CSS-Pixeln
            const combinedCutOutHeight = originalHeight; // Höhe in CSS-Pixeln
            console.log('Combined CutOut Width:', combinedCutOutWidth);
            console.log('Combined CutOut Height:', combinedCutOutHeight);

            // Hochladen des kombinierten CutOut-Bildes an Cloudflare R2
            const cutOutBlob = await (await fetch(combinedCutOutUrl)).blob();
            try {
                const cutOutLink = await uploadToR2(cutOutBlob); // Hochladen an Cloudflare R2
                console.log('Uploaded Cutout Image URL:', cutOutLink);

                // Hier rufst du die Funktion auf, um die URL in Firestore zu speichern
                const cutOutLinkToSave = cutOutLink || ''; // Setze auf einen leeren String, wenn cutOutLink undefined ist
                await updateImageInFirestore(userId, docRefId, cutOutLinkToSave, newModelImage, cutOutLink, combinedCutOutWidth, combinedCutOutHeight); // Übergebe die Breite und Höhe
            } catch (error) {
                console.error('Error uploading cutout image to Cloudflare:', error);
            }
        }
        return null; // Gebe null zurück, wenn kein Bild vorhanden ist
    };

    return (
        <div>
            <div
                ref={coordinateSystemRef} // Referenz für das Koordinatensystem
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '20px',
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: '#f9f9f9',
                }}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            >
                {images.map((image, index) => (
                    <div key={index} style={{ position: 'absolute', left: image.x, top: image.y }}>
                        <img
                            src={image.url}
                            alt={`Dropped ${index}`}
                            onMouseDown={(e) => handleMouseDown(index, e)} // Bild anklicken, um es zu ziehen
                            style={{
                                width: image.width,
                                height: image.height,
                                cursor: 'move',
                            }}
                        />
                        {/* Resize Handle */}
                        <div
                            onMouseDown={(e) => handleResizeMouseDown(index, e)} // Resize Handle anklicken
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '10px',
                                height: '10px',
                                backgroundColor: 'red',
                                cursor: 'nwse-resize',
                            }}
                        />
                    </div>
                ))}
            </div>
            <button onClick={combineAndUploadImages} style={{ marginTop: '20px' }}>
                Combine and Upload Images
            </button>

            {/* Anzeige des kombinierten Bildes */}
            {combinedImageUrl && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Combined Image:</h3>
                    <img
                        src={combinedImageUrl}
                        alt="Combined"
                        style={{
                            width: '100%', // Setze die Breite auf 100%
                            height: 'auto', // Höhe automatisch anpassen
                            display: 'block', // Block-Element, um unerwünschte Abstände zu vermeiden
                            margin: '0 auto', // Zentriere das Bild, wenn der Container breiter ist
                        }}
                    />
                </div>
            )}
            {/* Anzeige des kombinierten Cutout Bildes */}
            {combinedCutOut && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Combined Cutout Image:</h3>
                    <img
                        src={combinedCutOut}
                        alt="Combined Cutout"
                        style={{
                            width: '100%', // Setze die Breite auf 100%
                            height: 'auto', // Höhe automatisch anpassen
                            display: 'block', // Block-Element, um unerwünschte Abstände zu vermeiden
                            margin: '0 auto', // Zentriere das Bild, wenn der Container breiter ist
                        }}
                    />
                </div>
            )}

            {/* Anzeige der Positionen, Skalierungsfaktoren und Originalgrößen */}
            <div style={{ marginTop: '20px' }}>
                <h4>Image Positions, Scaling Factors, and Original Sizes:</h4>
                {images.map((image, index) => {
                    const originalWidth = originalDimensions[image.url]?.width || 0;
                    const originalHeight = originalDimensions[image.url]?.height || 0;

                    const scaleX = originalWidth ? (image.width / originalWidth).toFixed(2) : 0;
                    const scaleY = originalHeight ? (image.height / originalHeight).toFixed(2) : 0;
                    const positionY = 400 - image.y; // Position relativ zur unteren linken Ecke

                    const imageName = image.url === newModelImage ? 'newModelImage' : 'imageUrlRb';

                    return (
                        <div key={index}>
                            <p>
                                {`${imageName}: Position (X: ${image.x}, Y: ${positionY}), Scale (X: ${scaleX}, Y: ${scaleY}), Original Size (Width: ${originalWidth}, Height: ${originalHeight})`}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CoordinateSystem;
