import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { auth } from '../firebase'; // Import the authentication instance
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'; // Import Google Auth functions
import { firestore } from '../firebase'; // Import Firestore instance
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore functions

const Header = () => {
    const [user, setUser] = useState(null); // State to track the user
    const [error, setError] = useState(''); // State for error messages

    // Function to handle Google sign-in
    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider(); // Create a Google Auth provider
        try {
            const result = await signInWithPopup(auth, provider); // Sign in with Google
            console.log("header hat eingeloggt oder registriert");
            await configUser(result.user); // Call the function to create user in Firestore
            setUser(result.user); // Set the user state
        } catch (error) {
            setError(error.message); // Set error message
            console.error("Error during Google sign-in:", error.message); // Handle errors
        }
    };

    // New function to create user in Firestore
    const configUser = async (user) => {
        const userRef = doc(firestore, "users", user.uid); // Reference to the user document
        const userData = {
            email: user.email,
            createdAt: new Date(), // Set the creation date
            balance: 0, // Initial balance
            plan: "free", // Default plan
        };
        await setDoc(userRef, userData); // Save user data to Firestore
        return userData; // Return user data
    };

    // Function to handle sign-out
    const handleSignOut = async () => {
        try {
            await signOut(auth); // Sign out the user
            setUser(null); // Clear the user state
        } catch (error) {
            console.error("Error during sign-out:", error.message); // Handle errors
        }
    };

    // Effect to track authentication state
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user); // Update user state based on authentication status
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []);

    return (
        <header>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <h1 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                    Jewl AI 💎
                </h1>
            </Link>
            <nav>
                {user ? (
                    <>
                        <button onClick={handleSignOut} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer', padding: 0 }}>
                            Log Out
                        </button>
                        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message if exists */}
                    </>
                ) : (
                    <button onClick={handleGoogleSignIn} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer', padding: 0 }}>
                        Log In
                    </button>
                )}
            </nav>
        </header>
    );
};

export default Header;
