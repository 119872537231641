import React from 'react';
import xIcon from '../assets/x-icon.png'; // Importiere das X-Icon

function Footer() {
  return (
    <footer className="footer">
    <div className="footer-content">
    <p>&copy; 2024 JewlAI. All rights reserved.</p>
    <a href="https://x.com/janagoespro" target="_blank" rel="noopener noreferrer" className="twitter-link">
        Write a message <img src={xIcon} alt="X Icon" className="twitter-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;