import React from 'react';

function Navigation() {
  return (
    <nav>
      <ul>
      </ul>
    </nav>
  );
}

export default Navigation;